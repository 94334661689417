import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import CertificateService from "services/CertificateService";

export const initialState = {
  loadingAllData: false,
  loadingDeleteCertificate: false,
  certificates: [],
  createLoading: false,
  loadingOrder: false,
  updateLoading: false,
};

export const Create = createAsyncThunk(
  "/Certificate/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CertificateService.Create(data);
      if (response.success === false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetAll = createAsyncThunk(
  "/Certificate/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CertificateService.GetAll();
      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Certificate/SetActive",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CertificateService.Activate(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteCert = createAsyncThunk(
  "/Certificate/Delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CertificateService.Delete(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetById = createAsyncThunk(
  "/Certificate/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await CertificateService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Certificate/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await CertificateService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const ChangeOrder = createAsyncThunk(
  "/Certificate/ChangeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CertificateService.ChangeOrder(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state) => {
        state.createLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
      })
      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.certificates = action.payload;
      })
      .addCase(GetAll.rejected, (state) => {
        state.loadingAllData = false;
        state.certificates = [];
      })
      .addCase(DeleteCert.pending, (state) => {
        state.loadingDeleteCertificate = true;
      })
      .addCase(DeleteCert.fulfilled, (state, action) => {
        state.loadingDeleteCertificate = false;
      })
      .addCase(DeleteCert.rejected, (state, action) => {
        state.loadingDeleteCertificate = false;
      })
      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});

export const {} = certificateSlice.actions;

export default certificateSlice.reducer;
