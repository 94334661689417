import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import SocialMediaService from "services/SocialMediaService";

export const initialState = {
  loading: false,
  socialMedia: [],
  updateLoading: false,
};

export const Update = createAsyncThunk(
  "/SocialMedia/Update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SocialMediaService.Update(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: `Success !`,
        });

        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetAll = createAsyncThunk(
  "/SocialMedia/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SocialMediaService.GetAll();
      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const CreateList = createAsyncThunk(
  "/SocialMedia/CreateList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SocialMediaService.CreateList(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: `Success !`,
        });

        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetTypes = createAsyncThunk(
  "/SocialMedia/GetTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SocialMediaService.GetTypes();
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(GetTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.socialMedia = action.payload;
      })
      .addCase(GetTypes.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = socialMediaSlice.actions;

export default socialMediaSlice.reducer;
