import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import heroBannerService from "services/HeroBannerService";
import { message } from "antd";

export const initialState = {
  loadingAllBanners: false,
  loadingDeleteHeroBanner: false,
  allBanners: [],
  createLoading: false,
  loadingOrder: false,
};

export const UploadFile = createAsyncThunk(
  "/Carrousel/UploadFiles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.UploadFiles(data);
      if (response.success === false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetAll = createAsyncThunk(
  "/HeroBanner/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.GetAll();
      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const DeleteSlider = createAsyncThunk(
  "/HeroBanner/Delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.Delete(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ChangeOrder = createAsyncThunk(
  "/HeroBanner/ChangeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await heroBannerService.ChangeOrder(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const heroBannerSlice = createSlice({
  name: "heroBanner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(UploadFile.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(UploadFile.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(UploadFile.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(GetAll.pending, (state) => {
        state.loadingAllBanners = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllBanners = false;
        state.allBanners = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllBanners = false;
        state.allBanners = [];
      })
      .addCase(DeleteSlider.pending, (state) => {
        state.loadingDeleteHeroBanner = true;
      })
      .addCase(DeleteSlider.fulfilled, (state, action) => {
        state.loadingDeleteHeroBanner = false;
      })
      .addCase(DeleteSlider.rejected, (state, action) => {
        state.loadingDeleteHeroBanner = false;
      })
      .addCase(ChangeOrder.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(ChangeOrder.fulfilled, (state, action) => {
        state.loadingOrder = false;
      })
      .addCase(ChangeOrder.rejected, (state, action) => {
        state.loadingOrder = false;
      });
  },
});

export const {} = heroBannerSlice.actions;

export default heroBannerSlice.reducer;
