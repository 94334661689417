import fetch from "auth/FetchInterceptor";

const SocialMediaService = {};

SocialMediaService.Update = async function (data) {
  return fetch({
    url: "/SocialMedia/Update",
    method: "post",
    data,
  });
};

SocialMediaService.CreateList = async function (data) {
  return fetch({
    url: "/SocialMedia/CreateList",
    method: "post",
    data,
  });
};

SocialMediaService.GetTypes = async function () {
  return fetch({
    url: "/SocialMedia/GetTypes",
    method: "get",
  });
};

SocialMediaService.GetAll = async function () {
  return fetch({
    url: "/SocialMedia/GetAll",
    method: "get",
  });
};

export default SocialMediaService;
