import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import MailConfigService from "services/MailConfigService";

export const initialState = {
  loading: false,
  mailConfiguration: {},
  updateLoading: false,
};

export const GetMailSettings = createAsyncThunk(
  "/EmailConfiguration/GetMailSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await MailConfigService.GetMailSettings();
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const UpdateSettings = createAsyncThunk(
  "/EmailConfiguration/UpdateSettings",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MailConfigService.UpdateSettings(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: `Success !`,
        });

        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const mailConfigSlice = createSlice({
  name: "mailConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetMailSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetMailSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.mailConfiguration = action.payload;
      })
      .addCase(GetMailSettings.rejected, (state, action) => {
        state.mailConfiguration = {};
        state.loading = false;
      })
      .addCase(UpdateSettings.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(UpdateSettings.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(UpdateSettings.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});

export const {} = mailConfigSlice.actions;

export default mailConfigSlice.reducer;
