import fetch from "auth/FetchInterceptor";

const CertificateService = {};

CertificateService.Create = async function (data) {
  return fetch({
    url: "/Certificate/Create",
    method: "post",
    data,
  });
};

CertificateService.Update = async function (data) {
  return fetch({
    url: "/Certificate/Update",
    method: "post",
    data,
  });
};
CertificateService.GetAll = async function () {
  return fetch({
    url: "/Certificate/GetAll",
    method: "get",
  });
};

CertificateService.GetById = async function (params) {
  return fetch({
    url: "/Certificate/GetById",
    method: "get",
    params,
  });
};

CertificateService.SetActive = async function (data) {
  return fetch({
    url: "/Certificate/SetActive",
    method: "post",
    data,
  });
};

CertificateService.ChangeOrder = function (data) {
  return fetch({
    url: "/Certificate/ChangeOrder",
    method: "post",
    data,
  });
};

CertificateService.Delete = function (params) {
  return fetch({
    url: "/Certificate/Delete",
    method: "post",
    params,
  });
};

export default CertificateService;
