import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import banner from "./slices/bannerSlice";
import heroBanner from "./slices/heroBannerSlice";
import account from "./slices/accountSlice";
import contactUsForm from "./slices/contactUsFormSlice";
import mailConfig from "./slices/mailConfigSlice";
import categoryType from "./slices/categoryTypeSlice";
import category from "./slices/categorySlice";
import subCategory from "./slices/subCategorySlice";
import product from "./slices/productSlice";
import contactUs from "./slices/contactUsSlice";
import aboutUs from "./slices/aboutUsSlice";
import aboutUsDetails from "./slices/aboutUsDetailsSlice";
import homePage from "./slices/homePageSlice";
import termsConditions from "./slices/terms&ConditionsSlice";
import privacyPolicy from "./slices/privacyPolicySlice";
import homeSeo from "./slices/homeSeoSlice";
import certificate from "./slices/certificateSlice";
import socialMedia from "./slices/socialMediaSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    banner,
    account,
    subCategory,
    heroBanner,
    contactUsForm,
    homePage,
    homeSeo,
    privacyPolicy,
    product,
    termsConditions,
    contactUs,
    aboutUsDetails,
    aboutUs,
    category,
    categoryType,
    mailConfig,
    auth,
    certificate,
    socialMedia,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
