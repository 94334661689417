import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "services/ProductService";
import { message } from "antd";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  getAllData: [],
  createLoading: false,
  updateLoading: false,
  loadingGetByIdData: false,
  getByIdData: {},
  ssrData: [],
  loadingSSR: false,
  totalRecord: 0,
};

export const Create = createAsyncThunk(
  "/Product/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productService.Create(data);
      if (response.success === false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Product/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await productService.Update(data);

        if (response.success === false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Product/GetById",
  async ({ urlTitle }, { rejectWithValue }) => {
    try {
      const response = await productService.GetById({ urlTitle });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAllSSR = createAsyncThunk(
  "/Product/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productService.GetAllSSR(data);

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/Product/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await productService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Product/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productService.Activate(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSSR.pending, (state) => {
        state.loadingSSR = true;
      })
      .addCase(GetAllSSR.fulfilled, (state, action) => {
        state.loadingSSR = false;
        state.ssrData = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetAllSSR.rejected, (state) => {
        state.loadingSSR = false;
        state.ssrData = [];
      })

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      });
  },
});

export const {} = productSlice.actions;

export default productSlice.reducer;
