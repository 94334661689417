import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import PrivacyPolicyService from "services/PrivacyPolicyService";

export const initialState = {
  updateLoading: false,
  loadingGetDetails: false,
};

export const GetFirst = createAsyncThunk(
  "/PrivacyPolicy/GetFirst",
  async (_, { rejectWithValue }) => {
    try {
      const response = await PrivacyPolicyService.GetFirst();
      if (response.success === false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Update = createAsyncThunk(
  "/PrivacyPolicy/Update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await PrivacyPolicyService.Update(data);
      if (response.success === false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: `Success !`,
        });

        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetFirst.pending, (state) => {
        state.loadingGetDetails = true;
      })
      .addCase(GetFirst.fulfilled, (state, action) => {
        state.loadingGetDetails = false;
      })
      .addCase(GetFirst.rejected, (state, action) => {
        state.loadingGetDetails = false;
      })
      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});

export const {} = privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
